<template>
<Box>
  <ErrorRow 
    :error="error" />
  <SuccessRow 
    :message="message" />
  <SavingDialog v-show="showSavingDialog"   @close="closeSavingDialog" />

  <TitleRow> Payment Method </TitleRow>

  <Row :showBorder="true" v-if="!isLoading">
    <Column>
      
      <Box>
        <ValueRow>Credit Cards will be charged between the 15th and 30th of the month</ValueRow>
        <ValueRow>We do not accept American Express Cards</ValueRow>
        <KeyValue 
          title="ccName" 
          placeholder="Name on Credit Card" 
          :disabled="isSaving" 
          :defaultValue="ccCard.name()"
          v-on:value="setCcValue" />
        
        <KeyValue 
          title="ccNumber" 
          placeholder="Credit Card Number" 
          :disabled="isSaving" 
          v-on:value="setCcValue" />
        
        <Row>
          <Column :width="5">
            <Box>
              <KeyList
                title="ccMonth" 
                placeholder="Month"
                :options="Contents.MONTHS()"
                :defaultValue="ccCard.month()"
                :disabled="isSaving" 
                v-on:value="setCcValue" />
            </Box>
          </Column>
          <Column :width="5">
            <Box>
              <KeyList
                title="ccYear" 
                placeholder="Year"
                :options="Contents.YEARS()"
                :disabled="isSaving" 
                :defaultValue="ccCard.year()"
                v-on:value="setCcValue" />
            </Box>
          </Column>
          <Column :width="5">
            <Box>
              <KeyValue 
                title="ccCvc" 
                placeholder="CVC" 
                :disabled="isSaving" 
                :defaultValue="ccCard.cvc()"
                v-on:value="setCcValue" />
            </Box>
          </Column>
        </Row>
        
        <AddressRow 
          :showTitle="false"
          :addressData="ccAddressData" 
          v-on:value="setCcAddressValue" />
        
        <LabelValueRow v-if="isAdmin">
          <template #label>Reference #</template>
          <template #value>{{ ccCard.integrationReference() }}</template>
        </LabelValueRow>
        
        <SaveRow :left="4" :right="4" :disabled="false" v-on:value="save" />
        
        <SpacerRow />
      </Box>
    </Column>    
  </Row>
        
  
  <LoadingRow v-if="isLoading">{{ loadingMessage }} </LoadingRow>
  
</Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

//import CE from  "@/domain/session/CanadianEnglish.js";
import Catalog from  "@/domain/session/Catalog.js";
import InvoiceSettings from "@/domain/model/invoice/InvoiceSettings.js";
import Address from "@/domain/model/address/Address.js";
import Card from "@/domain/model/invoice/Card.js";

import ConstUtils from '@/utils/ConstUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import AddressRow from "@/components/input/address/AddressRow.vue";
import ErrorRow   from '@/components/row/ErrorRow.vue'
import SuccessRow from '@/components/row/SuccessRow.vue'
import SaveRow    from '@/components/row/SaveRow.vue'

import KeyValue from '@/components/input/KeyValue.vue';
import KeyList from '@/components/input/KeyList.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

//import Title from "@/portals/shared/library/title/Title.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import LoadingRow from '@/portals/shared/library/loading/LoadingRow.vue';

//import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

//import Button from "@/portals/shared/library/button/Button.vue";
//import Value from "@/portals/shared/library/value/Value.vue";
import ValueRow from "@/portals/shared/library/value/ValueRow.vue";

//import LabelValue from "@/portals/shared/library/labelvalue/LabelValue.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

export default {
  name: "operator-billing-edit",
  components: {
    TitleRow,
    LoadingRow,
    ErrorRow,
    SuccessRow,
    SaveRow,
    SpacerRow,
    AddressRow, SavingDialog,
    LabelValueRow, ValueRow,
    KeyValue, KeyList, //KeyCheck
    Box,Row, Column,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      isSaving: false,
      ccSettingsKvps: {},
      ccAddressKvps: {},
      MC: new Catalog(),
      Contents: ContentUtils,
      isLoading: true,
      loadingMessage: "",
      Card: Card,
      showSavingDialog: false,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                  ]),
    settings: function() {
      if (this.domain && this.domain.session()) {
        return this.domain.session().company().invoiceSettings();
      }
      return new InvoiceSettings(this.domain, {});
    },
    ccAddress: function() {
      return new Address(this.domain, this.ccAddressKvps);
    },
    ccAddressData: function() {
      if (this.domain) {
        return this.ccCard.address().find().data;
      }
      return {};
    },
    ccCard: function() {
      if (this.domain) {
        return this.settings.card().find();
      }
      return new Card(this.domain);
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = "Done";
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    setCcValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.ccSettingsKvps, kvp, this.domain.logger());
    },
    
    setCcAddressValue: function(kvp) {
      this.error = null;
      this.message = null;
      ContentUtils.set(this.ccAddressKvps, kvp, this.domain.logger());
    },
    
    save: function() {
      if (!this.requirementsMet()) {
        return;
      }
      this.error = null;
      this.showSavingDialog = true;
      this.isSaving = true;
      var event = this.domain.events().cards().save(this.ccSettingsKvps, this.ccAddressKvps);
      event.send(this.saveCardListener);
    },
    
    saveCardListener: function(event) {
      this.isSaving = false;
      this.showSavingDialog = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.message = "Card Saved";
        this.$router.push({
          name: ConstUtils.ROUTES.OPERATOR.BILLING_EDIT,
        })
      }
    },
    
    requirementsMet: function() {
      var met = true;
      return met;
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
  }
}

</script>